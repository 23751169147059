import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import pick from 'lodash/pick';

import {
  DATE_MONTHS,
  DATE_DAY_OF_WEEK,
  ADMIN_SCREEN_LEGACY_COLLECTION
} from '../constants/labels';

import {
  AdminScreensTableData
} from '../hooks/AdminScreenContext';
import { LOCALE_MATCH_STRATEGY } from '../constants/dynamicContentRow';
import { DynamicContentConf } from '../hooks/Types';
import { SeriesTableData } from '../hooks/CuratedMixContext';

type EpisodeItem = {
  cta_copy: string;
  cta_copy_secondary: string;
  episodeID: string;
  images?: { url: string }[];
};

interface ScreenRowParsedItem {
  type: string;
  order: number;
  label?: string;
  items?: { [key: string]: any }[];
  episodes?: EpisodeItem[];
  dynamicContentConf?: DynamicContentConf;
}
interface TableData extends ScreenRowParsedItem {
  id: string;
}

export const SCREEN_ROW_TYPES = {
  continueListening: 'continueListening',
  showRecommendationsByGenre: 'showRecommendationsByGenre',
  hero: 'hero',
  mixedContent: 'mixedContent',
  newEpisodes: 'newEpisodes',
  allSeries: 'allSeries',
  inlinePromo: 'inlinePromo',
  dynamicContent: 'dynamicContent',
};

type ScreenRowJson = {
  hero: {
    order: number;
    episodes: EpisodeItem[];
  };
  newEpisodes?: {
    order: number;
    label: string;
  };
  allSeries?: {
    order: number;
    label: string;
  };
  continueListening?: {
    order: number;
    label: string;
  };
  showRecommendationsByGenre?: {
    order: number;
    label: string;
  },
  mixedContent?: {
    lists: {
      order: number;
      label: string;
      body: string;
      items: {
        seriesID?: string;
        episodeID?: string;
        seasonID?: string;
      }[];
    }[];
  };
  inlinePromo?: {
    lists: {
      order: number;
      label: string;
      items: {
        seriesID?: string;
        episodeID?: string;
        seasonID?: string;
        action?: {
          actionType: string
          destination: string
        }
      }[];
    }[];
  };
  dynamicContent?: DynamicContentConf;
};

export const getPageTitle = (title: string) =>
  `${title && `${title} | `}Wondery Admin`;


const nestedRowTypes = {
  [SCREEN_ROW_TYPES.mixedContent]: 'lists',
  [SCREEN_ROW_TYPES.inlinePromo]: 'lists',
  [SCREEN_ROW_TYPES.dynamicContent]: null,
};

const parsePersistedRowsForRowType = <K extends keyof ScreenRowJson>(keyname: K, confForRowType: ScreenRowJson[K]): Omit<ScreenRowParsedItem, 'type'>[] => {
  if (!(keyname in nestedRowTypes)) {
    // a single row is specified in the configuration
    return [confForRowType as any];
  }
  // o/w, a 'nested' row type, ie. a list of rows is specified in the configuration
  const lookupAttr = nestedRowTypes[keyname];
  if (lookupAttr) {
    return (confForRowType as any)[lookupAttr];
  }
  return confForRowType as unknown as any[];
};

export const screenConfigParse: (data: { id: string, published: ScreenRowJson }) => TableData[] = ({ id, published }) => {
  const extendRowWithType = (keyname: keyof typeof SCREEN_ROW_TYPES, rowData: any) => ({
    type: keyname,
    ...rowData,
  });
  const rowItems = Object.keys(published)
    .map((keyname) => {
      const key = keyname as keyof ScreenRowJson;
      return parsePersistedRowsForRowType(key, get(published, `[${key}]`, {}))
        .map((row) => extendRowWithType(key, row));
    })
    .flat();

  return orderBy(rowItems, ['order'])
    .map((item: ScreenRowParsedItem, index) => ({
      id: `${id}-${index + 1}`,
      ...item,
    }));
};

// We need to prep the data to be backwards compatible with old mobile builds
function prepHeroData( hero: undefined | TableData ) {

  const heroItems:{ [key: string]: any }[] = get(hero, 'items', []);
  // Not sure why it would be but we should return as-is
  if ( !hero || !heroItems.length ) return hero;

  // Add episodes from items
  const episodes: EpisodeItem[] = [];
  for ( let i:number = 0; i < heroItems.length; i++ ) {
    const heroItem:{ [key: string]: any } = heroItems[i];
    const episodeID = get(heroItem, 'episodeID');
    if ( episodeID ) {
      episodes.push({
        episodeID,
        cta_copy: get(heroItem, 'cta_copy'),
        cta_copy_secondary: get(heroItem, 'cta_copy_secondary'),
        images: get(heroItem, 'images')
      });
    }
  }

  const formattedHero = {
    ...hero,
    episodes
  };

  console.log('prepHeroData: ', formattedHero);
  return formattedHero;
}

const formatDynamicContentRowForSave = (rowData: TableData) => {
  const { order, label, dynamicContentConf } = rowData;
  const { matchUserLanguage, matchUserCountry, ...dynamicContentConfRest } = dynamicContentConf || {};

  const getLocaleMatchConf = (destinationFieldName: string, localeMatch: LOCALE_MATCH_STRATEGY | undefined) =>
    (localeMatch && localeMatch !== LOCALE_MATCH_STRATEGY.NO) ? { [destinationFieldName]: localeMatch } : {};
  return {
    order,
    label,
    dynamicContentConf: {
      ...getLocaleMatchConf('matchUserLanguage', matchUserLanguage),
      ...getLocaleMatchConf('matchUserCountry', matchUserCountry),
      ...dynamicContentConfRest,
    },
  };
};

export const screenConfigStringify: (data: TableData[]) => string = (data = []) => {
  const hero = prepHeroData(
    data.find(({ type }) => type === SCREEN_ROW_TYPES.hero)
  );
  const continueListening = data.find(({ type }) => type === SCREEN_ROW_TYPES.continueListening);
  const showRecommendationsByGenre = data.find(({ type }) => type === SCREEN_ROW_TYPES.showRecommendationsByGenre);
  const allSeries = data.find(({ type }) => type === SCREEN_ROW_TYPES.allSeries);
  const newEpisodes = data.find(({ type }) => type === SCREEN_ROW_TYPES.newEpisodes);
  const mixedContent = {
    lists: data.filter(({ type }) => type === SCREEN_ROW_TYPES.mixedContent).map(item => pick(item, ['order', 'label', 'body', 'items', 'deviceTargets', 'targetEntitlement', 'keywords']))
  };
  const inlinePromo = {
    lists: data.filter(({ type }) => type === SCREEN_ROW_TYPES.inlinePromo)
      .map(item => pick(item, ['order', 'label', 'items', 'deviceTargets', 'targetEntitlement']))
  };

  const dynamicContent = data
    .filter(({ type }) => type === SCREEN_ROW_TYPES.dynamicContent)
    .map(formatDynamicContentRowForSave);

  return JSON.stringify({
    hero: pick(hero, ['order', 'episodes', 'items', 'images']),
    ...(continueListening ? { continueListening: pick(continueListening, ['order', 'label']) } : {}),
    ...(showRecommendationsByGenre ? { showRecommendationsByGenre: pick(showRecommendationsByGenre, ['order', 'label']) } : {}),
    ...(allSeries ? { allSeries: pick(allSeries, ['order', 'label']) } : {}),
    ...(newEpisodes ? { newEpisodes: pick(newEpisodes, ['order', 'label']) } : {}),
    ...(mixedContent ? { mixedContent } : {}),
    ...(dynamicContent ? { dynamicContent } : {}),
    ...(inlinePromo ? { inlinePromo } : {})
  });
};

export const formatAdminScreenDataForDisplay: (
  status: string, // "published" | "scheduled" | "draft" | "unpublished",
  data: AdminScreensTableData
) => AdminScreensTableData = ( status, data:AdminScreensTableData ) => {
  const newData:AdminScreensTableData = { ...data };

  if ( newData.futurePublishDate ) newData.futurePublishDate = stringifyDate(newData.futurePublishDate, status !== 'unpublished');
  if ( !newData.name ) newData.name = ADMIN_SCREEN_LEGACY_COLLECTION;
  if ( !newData.adminDisplayName && newData.admin ) newData.adminDisplayName = newData.admin;

  return newData;
};

export const getFieldsToKeysMappingByStatus: (status: string) => any = (status) => {
  // name, created, 'customColumnLabel', 'customColumnLabel2'
  const fieldMappings = {
    'published': {
      'name': 'name',
      'created': 'createdAt',
      'customColumnLabel': 'publishedDate',
      'customColumnLabel2': 'adminDisplayName'
    },
    'scheduled': {
      'name': 'name',
      'created': 'createdAt',
      'customColumnLabel': 'futurePublishDate',
      'customColumnLabel2': 'adminDisplayName'
    },
    'draft': {
      'name': 'name',
      'created': 'createdAt',
      'customColumnLabel': 'createdAt',
      'customColumnLabel2': 'adminDisplayName'
    },
    'unpublished': {
      'name': 'name',
      'created': 'publishedDate',
      'customColumnLabel': 'updatedAt'
    }
  };

  return get(fieldMappings, status);
};

export const buildAdminScreenDataRows: (
  status: string,
  data: AdminScreensTableData
) => any[] = ( status, data:AdminScreensTableData ) => {
  const rowData = [
    data.name,
  ];
  switch ( status ) {
    case 'published':
      rowData.push(
        data.publishedDate ? stringifyDate(data.publishedDate, true) : '',
        data.adminDisplayName
      );
      break;
    case 'scheduled':
      rowData.push(
        data.futurePublishDate,
        data.adminDisplayName
      );
      break;
    case 'draft':

      // Need to ask to see if some drafts will be cleared automatically by the BE system
      rowData.push(
        stringifyDate(data.createdAt, true),
        data.adminDisplayName
      );
      break;
    case 'unpublished':
      rowData.push(
        data.publishedDate ? stringifyDate(data.publishedDate, true) : '',
        stringifyDate(data.updatedAt, true)
      );
      break;
  }
  return rowData;
};

export const stringifyDate: (
  date: string | number | Date,
  includeTime?: boolean,
  separator?: string
) => string = (date, includeTime = true, separator = '') => {

  const newDate:Date = typeof(date) === 'string' ||
                       typeof(date) === 'number' ? new Date(date) : date;

  const dateStr = `${DATE_DAY_OF_WEEK[newDate.getDay()]} ${DATE_MONTHS[newDate.getMonth()]}, ${newDate.getDate()}`;
  const timeStr = !includeTime ? '' : `${newDate.getHours()}:${pad0(newDate.getMinutes())}:${pad0(newDate.getSeconds())}`;

  return `${dateStr} ${(separator?`${separator} `:'')}${timeStr}`;
};

function pad0(value:number) {
  return `${value<10?'0':''}${value}`;
}

export const getSeriesFieldsToKeysMapping: () => any = () => {
  // name, created, 'customColumnLabel', 'customColumnLabel2'

  return {
    'name': 'name',
    'created': 'createdAt',
  };
};

export const buildSeriesCuratedMixDataRows: (
  data: SeriesTableData
) => any[] = ( data:SeriesTableData ) => {
  const rowData = [
    data.title,
    data.createdAt,
  ];
  return rowData;
};

export const buildCuratedMixDataRows: (
  data: SeriesTableData
) => any[] = ( data:SeriesTableData ) => {
  const rowData = [
    data.title,
    data.createdAt,
  ];
  return rowData;
};